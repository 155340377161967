.title {
  padding: 20px 0;
  color: #fff;
  font-size: x-large;
}

.container {
  // background-color: #141414;
  margin-top: 20px;
  display: flex;
  padding: 20px;
  // @apply w-full grid grid-cols-1   lg:grid-cols-8;
  width: 100%;

  .leftContainer {
    // @apply   lg:col-span-6;

    padding: 0 0px;
    width: 100%;
    .leftbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      z-index: 2;
      position: absolute;
      margin-top: calc(100vw * 0.075);
      margin-left: -26px;
      &:hover {
        transition: all 0.2s ease;
        cursor: pointer;
        margin-top: calc(100vw * 0.07);
        width: 60px;
        height: 60px;
        margin-left: -30px;
      }
    }
    .rightbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      position: absolute;
      z-index: 2;
      margin-top: calc(100vw * 0.075);
      right: 34px;
      &:hover {
        transition: all 0.2s ease;
        cursor: pointer;
        margin-top: calc(100vw * 0.07);
        right: 30px;
        width: 60px;
        height: 60px;
      }
    }
    .moduleName {
      color: #fff;
      font-size: x-large;
      padding: 20px 0;
    }
    .content2 {
      // @apply grid grid-cols-2 gap-4 md:grid-cols-3   lg:grid-cols-6;
      display: flex;
      flex-wrap: nowrap;
      width: calc(100vw - 136px);

      overflow: hidden;
      .contenta {
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
        flex-grow: 1;
        margin: 0 8px;

        .lgbar {
          display: none;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          margin-top: -50px;
          padding: 0 10px;
          .label {
            padding: 2px 4px;
            background-color: rgba(255, 255, 255, 0.2);
            opacity: 1;
            border-radius: 2px;
            margin-right: 8px;
            font-size: 12px;
            height: 24px;
          }
          .btnCollect {
            color: #fff;
            width: 40px;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 40px;
            margin-right: 10px;
          }
          .mbtn {
            @apply bg-white;
            width: 40px;
            height: 40px;
            background-color: #00da5a;
            border-radius: 40px;

            &:hover {
              opacity: 0.8;
            }

            & > span {
              @apply font-semibold;
              color: #fff;
            }

            &.btnDetail {
              &:hover {
                @apply bg-white-40;
              }

              @apply bg-white-10 ml-4;

              & > span {
                @apply text-white;
              }
            }
          }
        }
        &:hover {
          .lgbar {
            display: flex;
          }
        }
      }
      // a {
      //   display: flex;
      //   justify-content: center;
      //   flex: 1 1 auto;
      //   flex-grow: 1;
      //   margin: 0 10px;
      // }
    }
    .cards {
      width: calc((100vw - 336px) / 5.5);
      height: calc((100vw - 336px) / 5.5 * 1.5 + 40px);
      border-radius: 6px;
      &:hover {
        transition: all 0.5s ease;
        width: calc(100vw / 3.6);

        .score {
          display: none;
        }

        .imgs {
          .img1 {
            display: none;
          }
          .img2 {
            display: block;
          }
        }
      }
      overflow: hidden;
    }
    .cardList {
      position: relative;
      color: #f5f5f5;
      // text-align: center;

      .cover {
        width: 100%;
        border-radius: 6px;
        object-fit: cover;
      }
      .imgs {
        height: calc((100vw - 336px) / 5.5 * 1.5 - 12px);
        border-radius: 6px;
        .img1 {
          border-radius: 6px;
          height: 100%;
          width: 100%;
        }
        .img2 {
          display: none;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          border-radius: 6px;
        }
      }

      .official {
        top: 0;
        right: 0px;
        width: 120px;
        position: absolute;
      }
      // 排行
      .TopNumContainer {
        position: absolute;
        padding: 0px;
        top: 0;
        width: 30px;
        .top {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
        }
        .topNum {
          position: absolute;
          top: 0;
          width: 30px;
          left: 0;
          text-align: center;
          right: 30px;
          font-weight: bold;
          color: #fff;
        }
      }
      // 分数
      .score {
        bottom: 58px;
        color: #f93;
        font-family: var(--rankFamily);
        font-size: 20px;
        // line-height: 20px;
        position: absolute;
        right: 8px;
        // -webkit-backdrop-filter: blur(5px);
        // backdrop-filter: blur(5px);
        font-weight: bold;
      }

      .name {
        padding: 5px 0px;
      }
      .desc {
        font-size: small;
        color: #666;
        min-height: 19.5px;
      }
    }
  }

  .rightContainer {
    @apply lg:col-span-2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    //  justify-content: center;
    align-items: center;
    border-radius: 10px;
    &:hover {
      .moduleName {
        .icon {
          transform: 0.5;
          width: 50px;
        }
      }
    }
    .moduleName {
      display: flex;
      align-items: center;
      // justify-content: center;
      color: #fff;
      font-size: x-large;
      padding: 20px 0;
      text-align: center;
      .icon {
        height: 36px;
        width: 36px;
      }
    }

    .content {
      // background-color: #000;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      padding: 10px 0 0 20px;
      color: #fff;
      // border-radius: 10px;

      width: 100%;
      height: 100%;
      .item {
        display: flex;
        margin: 5px;
        align-items: center;
        // background-color: #ffb60c;
        // border-radius: 10px;
        padding: 0 20px;
        background-color: #5fb878;
        width: 100%;
        .hotIndex {
          @apply w-5 h-5 text-center leading-5 rounded;
          background-color: #757575;

          &.one {
            background-color: #f92828;
          }

          &.two {
            @apply bg-theme;
          }

          &.three {
            background-color: #ffb60c;
          }
        }
        .name {
          font-weight: bold;
          font-size: large;
          padding: 20px;
          &:hover {
            color: red;
          }
          .label {
            // padding: 2px 5px;
            background-color: #5fb878;
            text-align: center;
            color: #fff;
            font-size: small;
            padding: 2px 5px;
          }
        }
        .img {
          width: 60px;
          height: 80px;
          border-radius: 3px;
        }
      }
    }
  }
}
